<template>
  <div
    class="map"
    v-shortkey="navigateTableShortkeys"
    @shortkey="handleShortKey"
    v-resize="resizeMap"
  >
    <v-app-bar
      app
      flat
      :height="mapTopHeight"
      color="#f7f9fc"
    >
      <div
        class="filters px-0"
        ref="tableTop"
        v-if="configured"
      >
        <div class="filters__inputs">
          <SearchBar
            :table-name="tableName"
            :config="coursesMap"
          />
          <StandingTime
            :table-name="tableName"
            :all-filters="readJSON(filters)"
            @changeFilters="changeFilters"
          />
          <DateRangePicker :table-name="tableName" />
          <ShowFiltersButton :table-name="tableName" />
        </div>
        <MapFilters
          :key="areResourcesBeingLoaded"
          :map-name="tableName"
          :filters="readJSON(allFilters)"
          :display-mode="displayMode"
          :show-display-mode="true"
          @changeDisplayMode="displayMode = $event"
        />
        <MapSwitch :view-name="tableName" />
      </div>
    </v-app-bar>
    <GmapMap
      ref="mapRef"
      :center="department.location"
      style="width:100%"
      :style="mapSize"
      :options="mapOptions"
    >
      <MapContent
        ref="mapContentRef"
        :base="department.location"
        :department="department"
        :courses="courses"
        :drivers="drivers"
        :map-ref="$refs.mapRef"
        :sidebar="!!sidebar.size"
      />
    </GmapMap>
    <div
      class="table-statistics"
      ref="statisticsBar"
    >
      <div class="table-statistics__value mr-2">
        {{ countCourses[0] }}
      </div>
      <div class="table-statistics__label">
        {{ countCourses[1] }}
      </div>
      <TableStatisticsBar
        :table-name="tableName"
        drivers-occupancy
      />
    </div>

    <Sidebar ref="preview">
      <SinglePreviewRoute />
    </Sidebar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { navigateTableShortkeys } from '../../const/shortKeys'
import { mapDirectionsParameters } from '../../helpers/mapOptions'
import Sidebar from '../../components/Layout/Sidebar/Sidebar'
import filtersMixin from '../../mixins/filtersMixin'
import SearchBar from '../../components/Filters/SearchBar'
import StandingTime from '../../components/Filters/StandingTime'
import DateRangePicker from '../../components/Filters/DateRangePicker'
import ShowFiltersButton from '../../components/Filters/ShowFiltersButton'
import MapFilters from '../../components/Map/MapFilters'
import MapContent from '../../components/Map/MapContent'
import MapSwitch from '../../components/Map/MapSwitch'
import SinglePreviewRoute from '../../components/Single/SinglePreviewRoute'
import TableStatisticsBar from '../../components/Table/TableStatisticsBar'
import { readJSON, declensionName } from '../../utils'
import webSocketMixin from '../../mixins/webSocketMixin'
import mapMixin from '../../mixins/mapMixin'

export default {
  components: {
    Sidebar,
    SearchBar,
    StandingTime,
    DateRangePicker,
    ShowFiltersButton,
    MapFilters,
    MapContent,
    MapSwitch,
    TableStatisticsBar,
    SinglePreviewRoute
  },
  mixins: [filtersMixin, webSocketMixin, mapMixin],
  data: () => ({
    tableName: 'coursesMap',
    navigateTableShortkeys,
    displayMode: 'all', // can be 'drivers', 'courses' or 'all' - filter defined in MapFilters due to no connection with BE
  }),
  channels: {
    MapChannel: { received (data) { this.captureChanges(data, 'coursesMap', true) } },
    DriverPositionsChannel: { received (data) { this.captureChanges(data, 'driversLocation', true) } }
  },
  computed: {
    ...mapState({
      coursesMap: state => state.tables.coursesMap,
      driversLocation: state => state.tables.driversLocation,
      sidebar: state => state.layout.sidebar,
      drivers: function (state) {
        if (this.displayMode === 'courses') return []
        return state.driversLocation.items
      },
      directions: state => state.route.entity.directions,
      showFilters: state => state.coursesMap.showFilters,
      mapCourses: state => state.coursesMap.items,
    }),
    mapDateRange () {
      return this.$store.state[this.tableName].dateRange
    },
    courses () {
      if (this.displayMode === 'drivers') return []
      return this.spreadOverlappingMarkers(readJSON(this.mapCourses), 'coursesMap')
    },
    countCourses () {
      const itemsCount = this.courses.length
      return declensionName(itemsCount, 'kurs', 'kursy', 'kursów').split(' ')
    },
    allFilters () {
      return this.coursesMap.filters.map((item) => {
        item.tableName = 'coursesMap'
        return item
      }).concat(
        this.driversLocation.filters.map((item) => {
          item.tableName = 'driversLocation'
          return item
        }))
    },
    mapSize () {
      const sidebarWidth = this.$refs.preview?.$el.offsetWidth || 0
      return {
        height: this.mapHeight + 'px',
        width: 'calc(100% - ' + (this.sidebar.size > 0 ? sidebarWidth : 0) + 'px)'
      }
    },
    directionsRenderer () {
      const directionsRenderer = new this.google.maps.DirectionsRenderer(mapDirectionsParameters)
      directionsRenderer.setMap(this.$refs.mapRef.$mapObject)
      return directionsRenderer
    }
  },
  watch: {
    directions (directions) {
      if (this.$refs.mapRef) {
        this.$refs.mapRef.$mapPromise.then(() => {
          this.directionsRenderer.setDirections(directions)
        })
      }
    },
    mapCourses: {
      deep: true,
      handler (value) {
        if (value.every((val) => !val.fromWS)) this.fitBounds(this.courses)
      }
    },
    mapDateRange (newDate) {
      this.resetRoutePlanning()
      const [channel] = this.activeChannels
      this.unsubscribeSocket(channel)
      this.subscribeSocket(channel, { dueDate: newDate[0] })
    }
  },
  created () {
    this.setTableConfig({ tableName: 'driversLocation' })
  },
  mounted () {
    this.subscribeSocket('MapChannel', { dueDate: this.mapDateRange[0] })
    this.subscribeSocket('DriverPositionsChannel')
  },
  beforeDestroy () {
    this.closeSidebar()
    this.unsubscribeSocket('MapChannel')
    this.unsubscribeSocket('DriverPositionsChannel')
  },
  methods: {
    ...mapActions({
      setSidebar: 'layout/setSidebar',
      closeSidebar: 'layout/closeSidebar',
      resetRoutePlanning: 'route/resetRoutePlanning'
    }),
    handleShortKey (event) {
      if (event.srcKey === 'esc') this.closeSidebar()
    }
  }
}
</script>
