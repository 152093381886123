import actions from '../../actions'
import orders from '../orders/orders'

export default {
  header: [
    { ...actions.checkPrice },
    { ...actions.addNewSwap },
    { ...actions.addNewPickup },
    { ...actions.addNewOrder, create: true }
  ],
  table: {
    isWebSocketHandled: true,
    maximumDateRange: 1,
    sorting: {
      sortBy: 'orderRefNumber',
      sortDesc: true
    },
    filters: [
      { name: 'courseStatuses', text: 'Status kursu', options: 'courseStatuses', filterBy: '', propPath: 'courseStatus', multiple: true, hide: true },
      { name: 'courseTypes', text: 'Typ kursu', options: 'courseTypes', filterBy: '', propPath: 'courseType', multiple: true },
      { name: 'containerTypeIds', text: 'Typ kontenera', options: 'containerTypes', filterBy: '', propPath: 'orderContainerTypeId', multiple: true },
      { name: 'driverIds', text: 'Kierowca', options: 'drivers', filterBy: '', propPath: 'driverId', multiple: true },
      { name: 'clientIds', text: 'Klient', options: 'clients', filterBy: '', propPath: 'orderClientId', multiple: true },
      { name: 'paymentTypes', text: 'Typ płatności', options: 'paymentTypes', filterBy: '', propPath: 'paymentType', multiple: true },
      { name: 'paymentStatuses', text: 'Status płatności', options: 'paymentStatuses', filterBy: '', propPath: 'paymentStatus', multiple: true },
      { name: 'clientBdoTypes', text: 'Logika BDO', options: 'allBdoTypes', filterBy: [], propPath: 'orderClientBdoType', multiple: true, hide: true },
      { name: 'bdoCardStatuses', text: 'Status KPO', options: 'bdoCardStatuses', filterBy: [], propPath: 'courseBdoStatus', multiple: true, hide: true },
      { name: 'withAssignedBdoCard', text: 'Wymaga KPO', filterBy: '', options: '', falseValue: '', propPath: 'withAssignedBdoCard', switch: true },
    ],
    parameters: [
      { name: 'select', text: '', value: 'select', show: true, sortable: false },
      { name: 'moveToRelatedCourse', text: 'Przejdź', show: true, sortable: false, tabs: ['courses'] },
      { name: 'refNumber', text: 'Nr zlecenia', value: 'orderRefNumber', swapValue: true, show: true },
      { name: 'sendSequence', text: 'Kolejność wysłania kursu', shortText: 'Kolejność...', value: 'sendSequence', show: true, sortable: true },
      { name: 'courseStatus', text: 'Status kursu', value: 'courseStatus', show: true },
      { name: 'orderMiddlemanName', text: 'Pośrednik', value: 'orderMiddlemanName', show: true },
      { name: 'courseType', text: 'Typ kursu', value: 'courseType', show: true },
      { name: 'containerId', text: 'Kontener', value: 'containerWithType', swapValue: true, show: true, cellClass: 'font-weight-bold' },
      { name: 'containerType', text: 'Zamówiony kontener', shortText: 'Zamówiony k...', value: 'orderContainerTypeName', swapValue: true, show: true, cellClass: 'font-weight-bold' },
      { name: 'debrisType', text: 'Kod odpadu', value: 'debrisTypeCode', swapValue: true, show: true },
      { name: 'aggregateName', text: 'Kruszywo', value: 'aggregateTypeDisplayName', swapValue: true, show: true, sortable: false },
      { name: 'bdoNumber', text: 'Nr BDO', value: 'orderClientBdoNumber', show: true, sortable: false },
      { name: 'kpoNumber', text: 'Nr KPO', value: 'courseKpoNumber', paramGetter: 'getBdoNumber', show: true },
      { name: 'bdoStatus', text: 'Status KPO', value: 'courseBdoStatus', paramGetter: 'getBdoStatus', show: true },
      { name: 'bdoType', text: 'Logika BDO', value: 'orderClientBdoType', show: true },
      { name: 'driverDailySequence', text: 'Nr kursu', shortText: 'Nr k...', value: 'driverDailySequence', show: true },
      { name: 'orderUserNotes', text: 'Notatka ogólna', value: 'orderUserNotes', show: true },
      { name: 'driver', text: 'Kierowca', value: 'driverFullName', show: true },
      { name: 'address', text: 'Adres zlecenia', value: 'orderAddressDisplayName', show: true },
      { name: 'commune', text: 'Gmina', value: 'orderCommuneName', show: true },
      { name: 'clientName', text: 'Klient', value: 'orderClientName', clientLabel: true, show: true },
      { name: 'phoneNumber', text: 'Numer telefonu', value: 'orderAddressPhoneNumber', show: true },
      { name: 'timeSlot', text: 'Godziny', value: 'courseTimeSlot', paramGetter: 'getCourseTimeSlot', show: true },
      { name: 'reportedDebrisType', text: 'Raportowany typ odpadu', value: 'reportedDebrisTypeDisplayName', swapValue: true, show: true },
      { name: 'aggregateAmount', text: 'Liczba ton', value: 'orderAggregateAmount', swapValue: true, show: true },
      { name: 'reportedWeight', text: 'Raportowana waga [T]', value: 'orderReportedWeight', swapValue: true, show: true },
      { name: 'totalGrossValue', text: 'Do zapłaty', value: 'paymentTotalGrossValueWithDiscount', swapValue: true, show: true },
      { name: 'paidValue', text: 'Pobrano', value: 'paymentPaidValue', swapValue: true, show: true },
      { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', swapValue: true, show: true },
      { name: 'documentStatus', text: 'Status dokumentu', value: 'withoutDocuments', sortable: false, show: true },
      { name: 'paymentStatus', text: 'Status płatności', value: 'paymentStatus', swapValue: true, show: true },
      { name: 'blocked', text: 'Blokada', value: 'orderClientBlocked', show: true, sortable: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.sendCourse, icon: 'wyslij_kurs' },
      { ...actions.cancelCourse, icon: 'cofnij_wyslanie_kursu' }
    ],
    menu: [
      { ...actions.editOrder, isCourse: true },
      { ...actions.removeFromTails, showInTabs: ['bdoCardObsolete'] },
      { ...actions.editCourseDueDate },
      { ...actions.copyOrder },
      { ...actions.sendCourse },
      { ...actions.cancelCourse },
      { ...actions.assignContainer, isCourse: true },
      { ...actions.assignOrder },
      { ...actions.unassignOrder },
      { ...actions.assignKpoCardToOrder },
      { ...actions.unassignKpoCard },
      { ...actions.messageDriver },
      { ...actions.showCourseHistory },
      { ...actions.pickupContainer, idPath: 'item.order.id', isCourse: true },
      { ...actions.swapContainer, idPath: 'item.order.id', isCourse: true },
      { ...actions.editClient, idPath: 'order.client.id', isCourse: true },
      { ...actions.editCourseAddress },
      { ...actions.deleteCourse },
      { ...actions.moveToTails, hideInTabs: ['bdoCardObsolete', 'coursesWithoutCards'] },
      { ...actions.startCourse },
      { ...actions.finishCourse },
      { ...actions.renewCourse },
      { ...actions.resendTextConfirmation },
      { ...actions.generateDigitalSignature },
    ]
  },
  details: {
    ...orders.details,
    emptyOrderSections: [
      {
        name: 'emptyOrder',
        text: 'Informacje ogólne',
        type: 'left',
        fields: [
          { name: 'containerType', text: 'Zamówiony typ kontenera', value: 'order.containerWithType', type: 'textField', col: 3 },
          { name: 'phoneNumber', text: 'Telefon', value: 'order.address.phoneNumber', type: 'textField', col: 3 },
          { name: 'formattedAddress', text: 'Adres lokalizacji', value: 'address.formattedAddress', type: 'textField', col: 6 }
        ]
      },
      {
        name: 'course',
        text: 'Szczegóły kursu',
        type: 'left',
        fields: [
          { name: 'courseType', text: 'Typ kursu', value: 'courseType', type: 'textField', col: 3 },
          { name: 'courseId', text: 'Nr kursu', value: 'id', type: 'textField', col: 3 },
          { name: 'courseStatus', text: 'Status kursu', value: 'courseStatus', type: 'textField', extraClass: 'course-status', col: 3 },
          { name: 'dueDate', text: 'Data realizacji', value: 'dueDate', type: 'date', col: 3 },
          { name: 'timeSlotWithNotes', text: 'Przedział godzinowy', value: 'timeSlot', type: 'timeSlot', col: 3, editable: true, fieldEndpoint: 'courses' },
          { name: 'driverFullName', text: 'Kierowca', value: 'driver.fullName', type: 'textField', col: 3 },
          { name: 'userNotes', text: 'Notatka jednorazowa', value: 'userNotes', type: 'textArea', col: 3 },
          { name: 'driverNotes', text: 'Notatka kierowcy', value: 'driverNotes', type: 'textArea', col: 3 }
        ]
      },
      {
        name: 'client',
        text: 'Dane klienta',
        type: 'right',
        fields: [
          { name: 'clientName', text: 'Nazwa klienta', value: 'order.client.name', type: 'textField', icon: 'client', extraClass: 'client-name', to: 'singleClient', idPath: 'client.id', col: 12 },
          { name: 'bdoType', text: 'Logika BDO', value: 'order.client.bdoType', type: 'textField', col: 6 },
          { name: 'bdoNumber', text: 'Nr BDO', value: 'order.client.bdoNumber', type: 'textField', col: 6 },
          { name: 'settlementType', text: 'Typ dokumentu', value: 'order.client.settlementType', type: 'textField', col: 6 },
          { name: 'blocked', text: 'Zablokowany', value: 'order.client.blocked', type: 'textField', col: 6 }
        ]
      },
      {
        name: 'kpo',
        text: 'Karta KPO/KPOK',
        multiple: 'bdoFile',
        type: 'right',
        component: 'DetailsSectionKPO'
      },
    ]
  },
}
