<template>
  <div
    class="map-switch"
    v-show="!sidebar.size"
  >
    <v-btn
      class="map-button"
      :class="{'map-button__active': isActive('coursesMap')}"
      icon
      @click="changeView('coursesMap')"
    >
      <Icon :name="isActive('coursesMap') ? 'map_courses_active' : 'map_courses'" />
    </v-btn>
    <v-divider
      vertical
      class="mx-2"
    />
    <v-btn
      class="map-button"
      :class="{'map-button__active': isActive('containersMap')}"
      icon
      @click="changeView('containersMap')"
    >
      <Icon :name="isActive('containersMap') ? 'map_containers_active' : 'map_containers'" />
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    viewName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar
    })
  },
  methods: {
    ...mapActions({
      setMapMode: 'core/setMapMode'
    }),
    changeView (view) {
      if (this.isActive(view)) return
      const navs = {
        containersMap: 'coursesMap',
        coursesMap: 'containersMap'
      }
      this.$router.push({ name: navs[this.viewName] })
      this.setMapMode(navs[this.viewName])
    },
    isActive (view) {
      return view === this.viewName
    }
  }
}
</script>

<style lang="scss" scoped>
.map-switch {
  background-color: white;
  position:absolute;
  display: flex;
  right:12px;
  top: calc(100% + 12px);
  padding: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);
}
.map-button {
  padding:12px;
  border: none !important;
  &__active {
    background-color: #F1F5F9 !important;
    border: 1px solid #D7E0EB !important;
  }
}

</style>
