const getFullName = name => `${name.firstName} ${name.lastName}`

const getBdoNumber = course => course.swapBdoCardNumber || course.bdoCardNumber || '-'
const getBdoStatus = course => course.swapBdoCardStatus || course.bdoCardStatus || '-'

const getCourseTimeSlot = course => {
  const { fromTime, toTime, timeNotes } = course
  const notes = timeNotes ? `(${timeNotes})` : ''
  return fromTime && toTime ? (fromTime + '-' + toTime + ' ' + notes) : '-'
}

export default {
  getFullName,
  getBdoNumber,
  getBdoStatus,
  getCourseTimeSlot
}
